<template>
  <div class="sctp-bg-white">
    <div class="sctp-pad15">
      <el-button type="primary" size="small" @click="editAddress">新增地址</el-button>
    </div>
    <el-dialog title="收货地址" :visible.sync="dialogFormVisible" append-to-body>
      <el-form ref="addressForm" :rules="addressFormRules" :model="address">
        <el-form-item label="收件人" :label-width="formLabelWidth" prop="receiver">
          <el-input v-model="address.receiver" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" :label-width="formLabelWidth" prop="call">
          <el-input v-model="address.call" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="收货地址" :label-width="formLabelWidth" prop="addressCode">
          <el-cascader :options="area" v-model="address.addressCode"></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" :label-width="formLabelWidth" prop="detailAddress">
          <el-input v-model="address.detailAddress" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="默认地址" :label-width="formLabelWidth">
          <el-switch
            :active-value="1"
            :inactive-value="0"
            v-model="address.defaultflag">
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="resetAddressForm">取 消</el-button>
        <el-button size="small" type="primary" @click="submitAddressForm('addressForm')">确 定
        </el-button>
      </div>
    </el-dialog>
    <el-table
      @selection-change="selectionChangeHandler"
      :data="addressArray"
      tooltip-effect="dark"
      style="width: 100%">
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column
        label="详细地址">
        <template slot-scope="scope"> {{ scope.row.address }} {{
            scope.row.detailAddress
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="receiver"
        label="默认地址"
        width="120">
        <template slot-scope="scope">
          <el-switch
            v-if="!scope.row.defaultflag"
            @change="setDefaultAddress(scope.row.addressid)"
            :active-value="1"
            :inactive-value="0"
            v-model="scope.row.defaultflag">
          </el-switch>
          <el-tag v-else>默认</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="receiver"
        label="收件人"
        width="120">
      </el-table-column>
      <el-table-column
        prop="call"
        label="联系电话"
        width="180">
      </el-table-column>
      <el-table-column
        label="操作"
        width="120">
        <template slot-scope="scope">
          <el-button @click="editAddress(scope.row)" size="small" type="primary" icon="el-icon-edit"
                     circle></el-button>
          <el-button @click="deleteAddress(scope.row.addressid)" size="small" type="danger"
                     icon="el-icon-delete" circle></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {address} from "@/apis/index";
import area from '../../../../resources/js/area'
import validator from '../../../../resources/js/async-validator'

export default {
  name: 'userAddress',
  data() {
    return {
      area: area.options,
      addressArray: [],
      dialogFormVisible: false,
      formLabelWidth: '120px',
      address: {
        addressCode: null,
        address: null,
        addressid: null,
        call: null,
        defaultflag: 0,
        detailAddress: null,
        receiver: null
      },
      addressFormRules: {
        receiver: [
          {required: true, message: '请输入收件人', trigger: 'blur'}
        ],
        call: [
          {required: true, message: '手机号不能为空', trigger: 'blur'},
          {validator: validator.validPhone, message: '手机号格式不正确', trigger: 'blur'}
        ],
        addressCode: [
          {required: true, message: '请选择经收货地址', trigger: 'blur'}
        ],
        detailAddress: [
          {required: true, message: '请输入详细地址', trigger: 'blur'}
        ]
      },
      selectionAddress: []
    }
  },
  methods: {
    selectionChangeHandler(selection) {
      this.selectionAddress = selection
    },
    submitAddressForm(formName) {
      this.$refs[formName].validate(res => {
        if (!res) {
          this.$message.warning('请检查表单内容')
          return
        }
        address.addAddress({
          userId: this.user.userId,
          areaCode: this.address.addressCode,
          area: area.codeToText(this.address.addressCode).join(','),
          phone: this.address.call,
          addressId: this.address.addressid,
          defaultFlag: this.address.defaultflag,
          receiver: this.address.receiver,
          addressDetails: this.address.detailAddress,
        }).then(res => {
          this.$message.success('保存成功')
          // 刷新地址列表
          this.getAddress();
          this.resetAddressForm();
        })
      })
    },
    setDefaultAddress(id) {
      this.$request.post({
        reqcode: '1029',
        reqdesc: '设置默认地址',
        addressId: id,
        userId: this.user.userId
      }).then(res => {
        this.$message.success('设置成功')
        this.getAddress()
      })
    },
    resetAddressForm() {
      this.dialogFormVisible = false
      Object.keys(this.address).forEach(key => {
        this.address[key] = null
      })
    },
    getAddress() {
      this.$request.post({
        reqcode: '1026',
        reqdesc: '收货地址',
        userId: this.user.userId
      }).then(res => {
        const {data = []} = res
        this.addressArray = data
      })
    },
    deleteAddress(id) {
      this.$confirm('此操作将删除该地址, 是否继续?', '提示', {
        type: 'warning'
      }).then(() => {
        this.$request.post({
          reqcode: '1027',
          reqdesc: '删除地址',
          addressId: id
        }).then(res => {
          this.$message.success('删除成功')
          let index
          if ((index = this.addressArray.findIndex(item => item.addressid === id)) !== -1) {
            this.addressArray.splice(index, 1)
          }
        })
      })
    },
    editAddress(target) {
      if (Object.prototype.toString.call(target) !== '[object MouseEvent]') {
        const temp = JSON.parse(JSON.stringify(target))
        temp.addressCode = (temp.addressCode || '').split(',').map(item => parseInt(item))
        // temp.addressCode = temp.addressCode.split(',').map(item => parseInt(item))
        this.address = temp
        // console.log(this.address)
      }
      this.dialogFormVisible = true
    }
  },
  beforeMount() {
    this.getAddress()
  }
}
</script>

<style scoped>
/*@formatter:off*/

  /*@formatter:on*/
</style>
